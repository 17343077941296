import React from "react";
import Services from "../home/Services.jsx";
import banner from "../../pages-header-background.jpg";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
const ServicesPage = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Our Services</title>
      </Helmet>
      <section
        className="internal"
        style={{
          background: `url(bg-inner.jpg)`,
          padding: "25vh 0 10vh",
          backgroundSize: "contain",
          backgroundPosition: "center center ",

          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="col-6 d-block text-center  py-2 m-auto"
          style={{ boxShadow: "0 0 5px #fff" }}
        >
          <h1>Our Services</h1>
          <ul class="breadcrumb">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>

            <li>Services</li>
          </ul>
        </div>
      </section>
      <section className="services services-boxed mega-section  " id="services">
        <div className="container">
          <div className="sec-heading  ">
            <div className="content-area mx-auto text-center">
              <span
                className=" pre-title    mx-auto   wow fadeInUp "
                data-wow-delay=".2s"
              >
                services
              </span>
              <h2 className=" title    wow fadeInUp" data-wow-delay=".4s">
                <span className="hollow-text">services</span> we offer
              </h2>
              <p className="subtitle   wow fadeInUp " data-wow-delay=".6s">
                a diverse range of services to cater to all your digital needs:
              </p>
            </div>
          </div>
          <div className="row gx-4 gy-4 services-row ">
            <div className="col-12 col-md-12  col-lg-12 mx-auto ">
              <div
                className="box service-box  wow fadeInUp reveal-start"
                data-wow-offset="0"
                data-wow-delay=".1s"
              >
                <div className="service-icon mx-auto text-center">
                  <i className="flaticon-web-development font-icon"></i>
                </div>
                <span className="service-num hollow-text ">1 </span>
                <div className="service-content text-center mx-auto">
                  <h3 className="service-title">Web development</h3>
                  <p className="service-text">
                    Our expert team specializes in creating custom, responsive
                    websites that are not only visually appealing but also
                    optimized for performance. We build websites that align with
                    your business goals and provide a seamless user experience
                    across all devices.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12  col-lg-12 mx-auto ">
              <div
                className="box service-box  wow fadeInUp reveal-start"
                data-wow-offset="0"
                data-wow-delay=".2s"
              >
                <div className="service-icon mx-auto text-center">
                  <i className="flaticon-nanotechnology font-icon"></i>
                </div>
                <span className="service-num hollow-text">2 </span>
                <div className="service-content mx-auto text-center">
                  <h3 className="service-title">Digital Marketing</h3>
                  <p className="service-text">
                    Elevate your brand with our tailored digital marketing
                    strategies. From social media management and content
                    creation to targeted advertising campaigns, we drive
                    engagement and increase your online presence to help you
                    reach your target audience effectively.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12  col-lg-12 mx-auto  ">
              <div
                className="box service-box  wow fadeInUp reveal-start"
                data-wow-offset="0"
                data-wow-delay=".3s"
              >
                <div className="service-icon mx-auto text-center">
                  <i className="flaticon-web-domain font-icon"></i>
                </div>
                <span className="service-num hollow-text">3 </span>
                <div className="service-content mx-auto text-center">
                  <h3 className="service-title">Medical products </h3>
                  <p className="service-text">
                    We offer specialized web solutions for the healthcare
                    industry, focusing on compliance, security, and
                    user-friendly interfaces. Our medical web solutions ensure
                    that your practice or facility can communicate effectively
                    with patients while adhering to industry regulations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12  col-lg-12 mx-auto  ">
              <div
                className="box service-box  wow fadeInUp reveal-start"
                data-wow-offset="0"
                data-wow-delay=".4s"
              >
                <div className="service-icon mx-auto text-center">
                  <i className="flaticon-profile font-icon"></i>
                </div>
                <span className="service-num hollow-text">4 </span>
                <div className="service-content mx-auto text-center">
                  <h3 className="service-title">Apps development</h3>
                  <p className="service-text">
                    Bring your ideas to life with our innovative app development
                    services. Whether you need a mobile application for iOS or
                    Android, our team develops high-quality, user-centric apps
                    that enhance functionality and improve user experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12  col-lg-12 mx-auto  ">
              <div
                className="box service-box  wow fadeInUp reveal-start"
                data-wow-offset="0"
                data-wow-delay=".5s"
              >
                <div className="service-icon mx-auto text-center">
                  <i className="flaticon-search font-icon"></i>
                </div>
                <span className="service-num hollow-text">5 </span>
                <div className="service-content mx-auto text-center">
                  <h3 className="service-title">SEO services</h3>
                  <p className="service-text">
                    Improve your website's visibility and search engine ranking
                    with our comprehensive SEO services. We conduct in-depth
                    keyword research, on-page optimization, and link-building
                    strategies to ensure that your site stands out in search
                    results and attracts more organic traffic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12  col-lg-12 mx-auto  ">
              <div
                className="box service-box  wow fadeInUp  reveal-start"
                data-wow-offset="0"
                data-wow-delay=".6s"
              >
                <div className="service-icon mx-auto text-center">
                  <i className="flaticon-strategy font-icon"></i>
                </div>
                <span className="service-num hollow-text">6 </span>
                <div className="service-content mx-auto text-center">
                  <h3 className="service-title">data analysis</h3>
                  <p className="service-text">
                    Leverage the power of data with our advanced data analysis
                    services. We provide actionable insights and detailed
                    reports that help you make informed business decisions,
                    optimize performance, and identify opportunities for growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesPage;
